import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'

import media from 'styles/media'

const Wrapper = styled.div`
  margin-bottom: 80px;

  ${media.lg.min} {
    margin-bottom: 0;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 30px;

  a {
    width: 100%;
    padding: 15px;
    text-transform: lowercase;
  }

  ${media.lg.min} {
    a {
      width: 250px;
    }
  }
`

type Props = {
  contactData: {
    address: string
    companyData: string
    phone: string
    emails: string[]
  }
}

const Info: React.FC<Props> = ({ contactData }) => {
  return (
    <Wrapper>
      <Text
        dangerouslySetInnerHTML={{ __html: contactData.address }}
        size={22}
        themecolor="white"
      />
      <Text
        dangerouslySetInnerHTML={{
          __html: `${contactData.companyData}`,
        }}
        line={1.8}
        margin="5px"
        themecolor="white"
      />
      <ButtonsWrapper>
        <ButtonWithIcon
          white
          as="a"
          href={`tel:${contactData.phone}`}
          icon="phone"
        >
          {contactData.phone}
        </ButtonWithIcon>
        {contactData.emails.map((email) => (
          <ButtonWithIcon
            key={email}
            white
            as="a"
            href={`mailto:${email}`}
            icon="email"
          >
            {email}
          </ButtonWithIcon>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default Info
