// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Title from 'components/pages/contact/title'
import Contact from 'components/pages/contact/contact'
import Questions from 'components/pages/contact/questions'
import Representatives from 'components/pages/contact/representatives'

// types
import { SalesRep } from 'types/homepage'

const KontaktPage: React.FC<PageProps<Queries.KontaktPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.contact
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const SALES_REPS: SalesRep[] =
    PAGE?.contactSalers
      ?.filter((handlowiec) => handlowiec?.contactSalerName)
      ?.map((salesRep) => ({
        image: {
          src: salesRep!.contactSalerImg!.localFile!.childImageSharp!
            .gatsbyImageData!,
          alt: salesRep!.contactSalerImg!.altText!,
        },
        name: salesRep!.contactSalerName!,
        position: salesRep!.contactSalerPosition!,
        region: salesRep!.contactSalerRegion!,
        phone: salesRep!.contactSalerPhoneNumber!,
        email: salesRep!.contactSalerEmail!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Title title="Kontakt" />
      <Contact
        heading="Masz pytania, sugestie lub wątpliwości? Napisz do nas"
        contactData={{
          address: PAGE?.contactCompanyName!,
          companyData: PAGE?.contactData!,
          phone: PAGE?.contactPhoneNumber!,
          emails:
            PAGE?.contactEmails?.map((email) => email?.contactEmailSingle!) ||
            [],
        }}
      />
      <Representatives
        heading="<strong>Jak</strong> <i>możemy</i><br/><strong>ci pomóc?</strong>"
        text={PAGE?.contactSalersDescription!}
        people={SALES_REPS}
      />
      <Questions
        heading="<i>Częste</i><br/><strong>pytania</strong>"
        text={PAGE?.contactFaqDescription!}
        buttonText={PAGE?.contactFaqButton!}
        buttonLink="/najczesciej-zadawane-pytania"
        image={{
          src: PAGE?.separatorImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.separatorImg?.altText!,
        }}
      />
    </Layout>
  )
}

export default KontaktPage

export const query = graphql`
  query KontaktPage {
    wpPage(slug: { regex: "/kontakt/" }) {
      contact {
        contactCompanyName
        contactData
        contactEmails {
          contactEmailSingle
        }
        contactFaqButton
        contactFaqDescription
        contactPhoneNumber
        contactSalersDescription
        contactSalers {
          contactSalerEmail
          contactSalerName
          contactSalerPhoneNumber
          contactSalerRegion
          contactSalerPosition
          contactSalerImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 280)
              }
            }
          }
        }
        separatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
  }
`
